.atodermland {
    &__banner { // MOD istniejącego atm-banner
        @include media-breakpoint-down (lg) {
            padding-bottom: 95%;
        }

        @include media-breakpoint-down (xl) {
            padding-bottom: 85%;
            height: auto;
        }
    }

    &__blue-box {
        margin-top: 30px;
        padding: 30px 20px;
        border-bottom: 4px solid #6e85d1;
        background: #92cbe6;
        background: linear-gradient(160deg, #92cbe6 0%, #6cbade 100%);
        -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.5);
        -moz-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.5);
    }

    &__blue-box-text {
        margin-bottom: 0 !important;
        color: #fff !important;
        font-size: 18px !important;
        line-height: 1.6 !important;
    }
}
