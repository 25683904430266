
/// Style dla nakładki sygnalizującej ładowanie mapy.
/// @name Searching
/// @group specialists
.specialists-searching {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.75);
    text-align: center;
    z-index: 999;

    &__content-box {
        &--error {
            padding: 50px;
            border: 3px solid $color-second;
            background-color: rgba(255, 255, 255, 0.9);
        }
    }

    &__icon-cog-box {
        &--animate {
            animation: rotating 2s linear infinite;
        }
    }

    &__icon-cog {
        vertical-align: sub;

        &::before {
            color: $color-second;
            font-size: 82px;
        }
    }

    @keyframes rotating {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }

    &__text {
        margin-top: 50px;
        margin-bottom: 0;
        font-family: $default-font;
        font-size: 48px;
        font-weight: 700;
        color: $color-second;
        line-height: normal;

        &--error {
            @include media-breakpoint-down (md) {
                margin-top: 25px;
                font-size: 20px;
            }
        }

        &--animate {
            &:after {
                content: ' .';
                animation: dots 2s steps(5, end) infinite;
            }
        }
    }

    @keyframes dots {
        0%,
        20% {
          color: rgba(0, 0, 0, 0);
          text-shadow:
            0.25em 0 0 rgba(0, 0, 0, 0),
            0.5em 0 0 rgba(0, 0, 0, 0);
        }
        40% {
          color: $color-second;
          text-shadow:
            0.25em 0 0 rgba(0, 0, 0, 0),
            0.5em 0 0 rgba(0, 0, 0, 0);
        }
        60% {
          text-shadow:
            0.25em 0 0 $color-second,
            0.5em 0 0 rgba(0, 0, 0, 0);
        }
        80%,
        100% {
          text-shadow:
            0.25em 0 0 $color-second,
            0.5em 0 0 $color-second;
        }
    }
}
