
/// Style dla sekcji Flexible FAQ.
/// @name FAQ
/// @group flexible
.flexible-faq {
    padding: 0 0 50px 0;

    &__text-box-wysiwyg {
        h1 {
            color: $old-font-blue;
            font-family: $default-font;
            font-size: 60px;
            font-weight: 500;
        }

        h2 {
            font-family: $default-font;
            font-size: 22px;
            font-weight: 700;
        }
    }

    &__panel {
        margin-bottom: 10px !important;
        border-width: 0;
        border-radius: 0 !important;

        .collapsed {
            &::after {
                transform: rotate(180deg);
            }
        }
    }

    &__panel-heading {
        position: relative;
        display: flex;
        height: 66px;
        padding-right: 80px;
        align-items: center;
        border-radius: 0px;
        background-color: #f8f8f8 !important;
        

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 66px;
            width: 69px;
            background-image: url('../img/faq/faq-up-arrow.png');
            background-repeat: no-repeat;
            background-position: right center;
        }

        &:hover {
            cursor: pointer;
        }
    }

    &__panel-title {
        margin: 0;
        color: $old-font-blue;
        font-family: $default-font;
        font-size: 16px;
        font-weight: 700;
        font-style: normal;
        line-height: initial;

        &--smaller {
            @include media-breakpoint-down (md) {
                font-size: 12px;
            }
        }
    }

    &__panel-body {
        border-top: 0 !important;
        background-color: #f8f8f8;
    }

    &__text {
        p {
            color: $color-first-dark;
            font-family: $default-font;
            font-size: 14px;

            img {
                height: auto;
                max-width: 100%;
            }
        }
    }
}
