@font-face {
	font-family: "iconfont";
	src: url('../iconfont/iconfont.eot');
	src: url('../iconfont/iconfont.eot?#iefix') format('eot'),
		url('../iconfont/iconfont.woff2') format('woff2'),
		url('../iconfont/iconfont.woff') format('woff'),
		url('../iconfont/iconfont.ttf') format('truetype'),
		url('../iconfont/iconfont.svg#iconfont') format('svg');
}

@mixin icon-styles {
	font-family: "iconfont";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	// speak: none; // only necessary if not using the private unicode range (firstGlyph option)
	text-decoration: none;
    text-transform: none;
    line-height: 1;
}

%icon {
	@include icon-styles;
}

@function icon-char($filename) {
	$char: "";

	@if $filename == check-mark {
		$char: "\E001";
	}
	@if $filename == cog {
		$char: "\E002";
	}
	@if $filename == corner {
		$char: "\E003";
	}
	@if $filename == doctor {
		$char: "\E004";
	}
	@if $filename == error {
		$char: "\E005";
	}
	@if $filename == locate {
		$char: "\E006";
	}
	@if $filename == magnifier {
		$char: "\E007";
	}
	@if $filename == map-marker {
		$char: "\E008";
	}
	@if $filename == minus {
		$char: "\E009";
	}
	@if $filename == nfz {
		$char: "\E00A";
	}
	@if $filename == pharmacy {
		$char: "\E00B";
	}
	@if $filename == plus {
		$char: "\E00C";
	}
	@if $filename == psychologist {
		$char: "\E00D";
	}
	@if $filename == zsoa {
		$char: "\E00E";
	}

	@return $char;
}

@mixin icon($filename, $insert: before, $extend: true) {
	&:#{$insert} {
		@if $extend {
			@extend %icon;
		} @else {
			@include icon-styles;
		}
		content: icon-char($filename);
	}
}

.icon-check-mark {
	@include icon(check-mark);
}
.icon-cog {
	@include icon(cog);
}
.icon-corner {
	@include icon(corner);
}
.icon-doctor {
	@include icon(doctor);
}
.icon-error {
	@include icon(error);
}
.icon-locate {
	@include icon(locate);
}
.icon-magnifier {
	@include icon(magnifier);
}
.icon-map-marker {
	@include icon(map-marker);
}
.icon-minus {
	@include icon(minus);
}
.icon-nfz {
	@include icon(nfz);
}
.icon-pharmacy {
	@include icon(pharmacy);
}
.icon-plus {
	@include icon(plus);
}
.icon-psychologist {
	@include icon(psychologist);
}
.icon-zsoa {
	@include icon(zsoa);
}
