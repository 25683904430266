// Colors
$color-first-light: #53bdec;
$color-first: #0775a6;
$color-first-dark: #143e6f;
$color-second: #e93c8f;
$color-second-dark: #e6167a;
$color-third: #58585a;

// Fonts
$default-font: 'Raleway', sans-serif;

// Old vars from LESS
$old-accent-blue: #4374a6;
$old-accent-grey: #7b8fa4;
$old-accent-pink: #e93d8f;

$old-font-blue: #546886;
$old-font-violet: #3f3e42;
$old-font-gray: #3f3e42;