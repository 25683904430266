
/// Style dla contentu wewnątrz "wiedzy". Style SCSS BEM nadpisują stare ostylowania LESS.
/// @name Content
/// @group knowledge
.knowledge-content {
    &__title {
        font-size: 46px !important;
        margin-bottom: 20px !important;

        @include media-breakpoint-down (md) {
            font-size: 26px !important;
        }
    }
}
