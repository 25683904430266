
/// Style dla boxów promo na stronie z bazą specjalistów.
/// @name Promo
/// @group specialists
.specialists-promo {
    &__row {
        margin-bottom: 30px;
    }

    &__text-box {
        padding: 30px 85px;
        background-color: #f4f8fb;

        @include media-breakpoint-down (md) {
            padding: 15px;
        }

        &--spacer {
            padding: 80px 85px;

            @include media-breakpoint-down (md) {
                padding: 15px;
            }
        }

        &--right {
            @include media-breakpoint-down (sm) {
                width: 100%;
            }
        }
    }

    &__text {
        margin-bottom: 10px;
        font-family: $default-font;
        font-size: 14px;
        font-weight: 400;
        color: $color-first;

        &--spacer {
            padding: 20px 0;
        }

        &--smaller {
            font-size: 13px;
        }
    }

    &__link {
        text-decoration: underline;
    }

    &__image-container {
        position: relative;
        overflow: hidden;

        @include media-breakpoint-down (md) {
            height: 280px;
            width: 100%;
        }

        &--left {
            padding-right: 0;

            @include media-breakpoint-down (sm) {
                width: 100%;
            }
        }

        &--right {
            padding-left: 0;
        }

        &--mobile {
            display: none;

            @include media-breakpoint-down (md) {
                display: block;
            }
        }

        &--desktop {
            @include media-breakpoint-down (md) {
                display: none;
            }
        }
    }

    &__image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-down (lg) {
            height: 100%;
        }
    }

    /* &__logo-ptca {
        float: left;
        margin-right: 25px;
    } */
}
