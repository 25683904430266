/**
 * Plik zawiera nadpisania domyślnych styli dla custom'owego scrollbar'a.
 */

// Linia przesuwania
.mCSB_scrollTools .mCSB_draggerRail {
    width: 5px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.mCS-zsoa.mCSB_scrollTools .mCSB_draggerRail {
    background-color: #d0d8e2;
}

// Dragger
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    width: 7px;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.mCS-zsoa.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background-color: $color-first-dark;
}

// Dragger - Hover
.mCS-zsoa.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
    background-color: $color-first-dark;
}

// Dragger - Active
.mCS-zsoa.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-zsoa.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
    background-color: $color-first-dark;
}
