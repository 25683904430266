
/// Style dla sekcji Flexible Text oraz Text Two Cols/etc.
/// @name Text
/// @group flexible
.flexible-text {
    &__column {
        &--first {
            @include media-breakpoint-down (md) {
                margin-bottom: 20px;
            }
        }
    }
}
