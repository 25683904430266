@charset "UTF-8";
.icon {
  vertical-align: sub;
}

/**
 * Czcionki
 * @include font-face({font-name}, '../fonts/{font-folder}/{font-file-name}', {font-weight}, {font-style}, {font-type: woff ttf woff2});
 */
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/Raleway/raleway-regular.woff") format("woff"), url("../fonts/Raleway/raleway-regular.ttf") format("truetype"), url("../fonts/Raleway/raleway-regular.woff2") format("woff2");
}

@font-face {
  font-family: "iconfont";
  src: url("../iconfont/iconfont.eot");
  src: url("../iconfont/iconfont.eot?#iefix") format("eot"), url("../iconfont/iconfont.woff2") format("woff2"), url("../iconfont/iconfont.woff") format("woff"), url("../iconfont/iconfont.ttf") format("truetype"), url("../iconfont/iconfont.svg#iconfont") format("svg");
}

.icon-check-mark:before, .icon-cog:before, .icon-corner:before, .icon-doctor:before, .icon-error:before, .icon-locate:before, .icon-magnifier:before, .icon-map-marker:before, .icon-minus:before, .icon-nfz:before, .icon-pharmacy:before, .icon-plus:before, .icon-psychologist:before, .icon-zsoa:before, .specialists-map__contact-title:before, .specialists-map__contact-title:after {
  font-family: "iconfont";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-decoration: none;
  text-transform: none;
  line-height: 1;
}

.icon-check-mark:before {
  content: "";
}

.icon-cog:before {
  content: "";
}

.icon-corner:before {
  content: "";
}

.icon-doctor:before {
  content: "";
}

.icon-error:before {
  content: "";
}

.icon-locate:before {
  content: "";
}

.icon-magnifier:before {
  content: "";
}

.icon-map-marker:before {
  content: "";
}

.icon-minus:before {
  content: "";
}

.icon-nfz:before {
  content: "";
}

.icon-pharmacy:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-psychologist:before {
  content: "";
}

.icon-zsoa:before {
  content: "";
}

/**
* Plik zawiera modyfikacje oryginalnego Bootstrap + nowe klasy rozszerzające możliwości Bootstrap'a.
*/
button:focus,
button:active {
  outline: none !important;
  box-shadow: none !important;
}

input:focus,
input:active {
  outline: none !important;
  box-shadow: none !important;
}

.row-eq-height {
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.col-eq-height-first {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

@media (max-width: 991px) {
  .col-eq-height-first {
    flex: unset;
  }
}

.modal-backdrop {
  background-color: #fff;
}

.has-error .form-control {
  border-color: #f00;
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
  color: #f00;
}

/**
 * Plik zawiera nadpisania domyślnych styli dla custom'owego scrollbar'a.
 */
.mCSB_scrollTools .mCSB_draggerRail {
  width: 5px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.mCS-zsoa.mCSB_scrollTools .mCSB_draggerRail {
  background-color: #d0d8e2;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  width: 7px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.mCS-zsoa.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #143e6f;
}

.mCS-zsoa.mCSB_scrollTools .mCSB_dragger:hover .mCSB_dragger_bar {
  background-color: #143e6f;
}

.mCS-zsoa.mCSB_scrollTools .mCSB_dragger:active .mCSB_dragger_bar,
.mCS-zsoa.mCSB_scrollTools .mCSB_dragger.mCSB_dragger_onDrag .mCSB_dragger_bar {
  background-color: #143e6f;
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

ul.slick-dots {
  list-style: none;
  padding: 0;
  margin: 0;
  font: 0/0 a;
}

ul.slick-dots > li {
  padding: 0;
  margin: 0;
  display: inline-block;
  font: 0/0 a;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}

ul.slick-dots > li > button {
  font-size: 0 a;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: none;
  background: #e5ebef;
  margin-right: 10px;
  outline: 0;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}

ul.slick-dots > li.slick-active > button, ul.slick-dots > li:hover > button {
  background: #e93d8f;
}

@media (max-width: 1199px) {
  .atodermland__banner {
    padding-bottom: 95%;
  }
}

@media (max-width: 1499px) {
  .atodermland__banner {
    padding-bottom: 85%;
    height: auto;
  }
}

.atodermland__blue-box {
  margin-top: 30px;
  padding: 30px 20px;
  border-bottom: 4px solid #6e85d1;
  background: #92cbe6;
  background: linear-gradient(160deg, #92cbe6 0%, #6cbade 100%);
  -webkit-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.5);
}

.atodermland__blue-box-text {
  margin-bottom: 0 !important;
  color: #fff !important;
  font-size: 18px !important;
  line-height: 1.6 !important;
}

.button {
  color: #fff;
  border-radius: 0;
  background: #546886;
  border: 1px solid #546886;
  min-height: 37px;
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  margin: 5px 10px 5px 0;
  padding: 10px 25px;
  max-width: 100%;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
}

.button:hover {
  background: transparent;
  color: #546886;
}

.button-blue {
  background: #4374a6;
  border: 1px solid #4374a6;
}

.button-blue:hover {
  background: transparent;
  color: #4374a6;
}

.button-grey {
  background: #7b8fa4;
  border: 1px solid #7b8fa4;
}

.button-grey:hover {
  background: transparent;
  color: #7b8fa4;
}

a.button {
  text-decoration: none;
}

a.button:hover, a.button:active, a.button:visited, a.button:focus {
  text-decoration: none;
}

.cookie-info {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100vw;
  padding: 20px 0 10px 0;
  background-color: #7b8fa4;
  z-index: 1000;
}

.cookie-info__text {
  margin: 0;
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

@media (max-width: 991px) {
  .cookie-info__text {
    margin-bottom: 10px;
  }
}

.cookie-info__buttons {
  margin-top: 8px;
  text-align: center;
}

.cookie-info__button {
  margin: 0 13px;
  padding: 6px 16px;
  border: 1px solid transparent;
  color: #fff;
  background-color: #e93c8f;
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.25s;
}

.cookie-info__button:hover, .cookie-info__button:focus, .cookie-info__button:active {
  color: #fff;
  background-color: #0775a6;
  border-color: #143e6f;
}

@media (max-width: 991px) {
  .cookie-info__button {
    margin: 0 3px;
    padding: 6px 10px;
  }
}

.cookie-info__icon-check-mark {
  margin-right: 2px;
  vertical-align: middle;
}

.flexible-faq {
  padding: 0 0 50px 0;
}

.flexible-faq__text-box-wysiwyg h1 {
  color: #546886;
  font-family: "Raleway", sans-serif;
  font-size: 60px;
  font-weight: 500;
}

.flexible-faq__text-box-wysiwyg h2 {
  font-family: "Raleway", sans-serif;
  font-size: 22px;
  font-weight: 700;
}

.flexible-faq__panel {
  margin-bottom: 10px !important;
  border-width: 0;
  border-radius: 0 !important;
}

.flexible-faq__panel .collapsed::after {
  transform: rotate(180deg);
}

.flexible-faq__panel-heading {
  position: relative;
  display: flex;
  height: 66px;
  padding-right: 80px;
  align-items: center;
  border-radius: 0px;
  background-color: #f8f8f8 !important;
}

.flexible-faq__panel-heading::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 66px;
  width: 69px;
  background-image: url("../img/faq/faq-up-arrow.png");
  background-repeat: no-repeat;
  background-position: right center;
}

.flexible-faq__panel-heading:hover {
  cursor: pointer;
}

.flexible-faq__panel-title {
  margin: 0;
  color: #546886;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  line-height: initial;
}

@media (max-width: 991px) {
  .flexible-faq__panel-title--smaller {
    font-size: 12px;
  }
}

.flexible-faq__panel-body {
  border-top: 0 !important;
  background-color: #f8f8f8;
}

.flexible-faq__text p {
  color: #143e6f;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
}

.flexible-faq__text p img {
  height: auto;
  max-width: 100%;
}

@media (max-width: 991px) {
  .flexible-text__column--first {
    margin-bottom: 20px;
  }
}

section.poster-video {
  margin-top: 60px;
}

@media (max-width: 575px) {
  section.poster-video {
    margin-top: 40px;
  }
}

section.poster-video .video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 */
  overflow: hidden;
  background-color: #e2f4fe;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

section.poster-video .video-container--low {
  padding-bottom: 30%;
}

section.poster-video .video-container.is-playing:hover .stop-button {
  top: 50%;
}

section.poster-video .video-container.is-playing iframe {
  z-index: 1;
}

section.poster-video .play-button {
  display: block;
  position: absolute;
  width: 70px;
  height: 120px;
  left: 50%;
  top: 50%;
  margin-left: -35px;
  margin-top: -60px;
  text-align: center;
  z-index: 5;
}

@media (max-width: 575px) {
  section.poster-video .play-button {
    margin-top: -35px;
  }
}

section.poster-video .play-button button {
  left: 0;
  width: 70px;
  height: 70px;
  background: url("../img/video/play.png") no-repeat center center;
  background-size: cover;
  border: none;
  position: absolute;
  top: 0;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
  outline: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

section.poster-video .play-button button:hover {
  width: 80px;
  height: 80px;
  left: -5px;
  top: -5px;
}

section.poster-video .play-button button:active {
  width: 60px;
  height: 60px;
  left: 5px;
  top: 5px;
}

section.poster-video .play-button p {
  margin: 0;
  font-size: 12px;
  line-height: 12px;
  color: #546886;
  font-weight: 700;
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
}

@media (max-width: 575px) {
  section.poster-video .play-button p {
    display: none;
  }
}

section.poster-video .stop-button {
  display: block;
  position: absolute;
  width: 70px;
  height: 70px;
  left: 50%;
  top: -50%;
  margin-left: -35px;
  margin-top: -35px;
  text-align: center;
  z-index: 5;
  -webkit-transition: 0.5s ease-out top;
  -moz-transition: 0.5s ease-out top;
  -ms-transition: 0.5s ease-out top;
  -o-transition: 0.5s ease-out top;
}

section.poster-video .stop-button button {
  left: 0;
  width: 70px;
  height: 70px;
  background: url("../img/video/stop.png") no-repeat center center;
  background-size: cover;
  border: none;
  position: absolute;
  top: 0;
  -webkit-transition: 0.3s all;
  -moz-transition: 0.3s all;
  -ms-transition: 0.3s all;
  -o-transition: 0.3s all;
  outline: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
}

section.poster-video .stop-button button:hover {
  width: 80px;
  height: 80px;
  left: -5px;
  top: -5px;
}

section.poster-video .stop-button button:active {
  width: 60px;
  height: 60px;
  left: 5px;
  top: 5px;
}

section.poster-video iframe {
  background-color: #e2f4fe;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
}

footer {
  background: #fff;
  padding: 50px 0;
}

@media (max-width: 575px) {
  footer {
    padding: 40px 0;
  }
}

footer .bioderma {
  margin-bottom: 20px;
}

footer ul.icon-list {
  padding: 0 0 10px 0;
  list-style: none;
  display: inline-block;
  border-bottom: 1px solid #c2c2c2;
}

footer ul.icon-list > li {
  display: inline-block;
  margin-left: 15px;
  margin-right: 15px;
}

footer h5 {
  margin-top: 20px;
  font-size: 10px;
  color: #626262;
}

footer.sticky {
  position: relative;
}

.page-template-atodermolandia footer {
  padding: 0 0 50px 0;
}

nav ul.nav > li.logo {
  margin-left: 50px;
  margin-right: 50px;
}

@media (max-width: 1499px) {
  nav ul.nav > li.logo {
    margin-left: 30px;
    margin-right: 30px;
  }
}

@media (max-width: 1199px) {
  nav ul.nav > li.logo {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (max-width: 575px) {
  nav ul.nav > li.logo {
    margin: 0;
  }
}

div.rect {
  overflow: hidden;
  background: #f1f9fe;
  position: relative;
  padding-bottom: 85%;
}

@media (max-width: 575px) {
  div.rect {
    padding-bottom: 0;
    height: auto;
  }
}

div.rect:before {
  border-left: 30px solid #fff;
  border-top: 30px solid #fff;
  border-right: 30px solid transparent;
  border-bottom: 30px solid transparent;
  content: '';
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
}

div.rect:after {
  border-left: 20px solid #546886;
  border-top: 20px solid #546886;
  border-right: 20px solid transparent;
  border-bottom: 20px solid transparent;
  content: '';
  position: absolute;
  z-index: 2;
  left: 0;
  top: 0;
}

div.rect .embed {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  z-index: 0;
}

div.rect .embed--mobile {
  display: none !important;
}

@media (max-width: 991px) {
  div.rect .embed--mobile {
    display: block !important;
  }
}

div.rect .inner {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  padding: 50px 50px 100px 50px;
  width: 100%;
  height: 100%;
  z-index: 3;
}

@media (max-width: 575px) {
  div.rect .inner {
    position: relative;
    padding: 40px 40px 100px 40px;
  }
}

div.rect .inner h2 {
  font-size: 28px;
  line-height: 30px;
  color: #546886;
  width: 50%;
}

@media (max-width: 767px) {
  div.rect .inner h2 {
    width: 100%;
    margin-bottom: 15px;
  }
}

@media (max-width: 991px) {
  div.rect .inner p {
    height: auto;
  }
}

@media (max-width: 767px) {
  div.rect .inner p {
    height: auto;
    margin-bottom: 15px;
  }
}

@media (max-width: 575px) {
  div.rect .inner p {
    height: auto;
  }
}

div.rect .inner a {
  position: absolute;
  bottom: 30px;
}

div.rect .button {
  border: 1px solid #f1f9fe;
}

div.rect .button:hover {
  background: #f1f9fe;
}

div.rect.blue {
  background: #f1f9fe;
}

div.rect.blue:after {
  border-left: 20px solid #4374a6;
  border-top: 20px solid #4374a6;
  border-right: 20px solid transparent;
  border-bottom: 20px solid transparent;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
}

div.rect.blue .inner h2 {
  color: #4374a6;
}

div.rect.blue .button {
  background: #4374a6;
  border: 1px solid #f1f9fe;
}

div.rect.blue .button:hover {
  background: #f1f9fe;
}

div.rect.pink {
  background: #f8f8f8;
}

div.rect.pink:after {
  border-left: 20px solid #e93d8f;
  border-top: 20px solid #e93d8f;
  border-right: 20px solid transparent;
  border-bottom: 20px solid transparent;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
}

div.rect.pink .inner h2 {
  color: #e93d8f;
}

div.rect.pink .button {
  background: #e93d8f;
  border: 1px solid #f8f8f8;
}

div.rect.pink .button:hover {
  background: #f8f8f8;
}

@media (max-width: 767px) {
  div.rect {
    margin-bottom: 30px;
  }
}

@media (max-width: 991px) {
  div.rect {
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  div.rect {
    margin-bottom: 30px;
  }
}

.e404 {
  padding: 200px 0 150px 0;
}

@media (max-width: 991px) {
  .e404 {
    padding: 50px 0;
  }
}

.e404__row {
  text-align: center;
}

.e404__title {
  color: #e93c8f;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

.e404__button {
  display: inline-block;
  margin-top: 50px;
  padding: 16px;
  border: 1px solid transparent;
  color: #fff;
  background-color: #e93c8f;
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.5s;
}

.e404__button:hover, .e404__button:focus, .e404__button:active {
  color: #fff;
  background-color: #0775a6;
  border-color: #143e6f;
  text-decoration: none;
}

@media (max-width: 991px) {
  .e404__button {
    padding: 10px;
    font-size: 18px;
  }
}

.slick-slide .banner-mobile-img {
  display: none;
}

@media (max-width: 767px) {
  .slick-slide .banner-mobile-img {
    display: block;
    position: absolute;
    bottom: 0;
    max-width: 100%;
    height: auto;
    z-index: 1;
  }
}

@media (max-width: 767px) {
  header .banner {
    background-image: none !important;
  }
}

header .banner .container {
  position: relative;
  z-index: 5;
}

header .banner p {
  font-size: 16px;
  line-height: 24px;
}

.knowledge-content__title {
  font-size: 46px !important;
  margin-bottom: 20px !important;
}

@media (max-width: 991px) {
  .knowledge-content__title {
    font-size: 26px !important;
  }
}

.products_heading {
  background-image: url("../img/products/emolient_bg.png");
  background-repeat: no-repeat;
  background-position: right 10% center;
  margin-bottom: 60px;
}

@media (max-width: 575px) {
  .products_heading {
    margin-bottom: 40px;
  }
}

.products_heading h1 {
  font-family: 'Raleway', sans-serif;
  font-size: 70px;
  font-weight: 200;
  color: #e93d8f;
  text-align: center;
  margin-right: 186px;
  line-height: 114px;
  padding-bottom: 35px;
  padding-left: 47px;
  padding-right: 47px;
  padding-top: 10px;
  position: relative;
  margin-bottom: 33px;
}

@media (max-width: 575px) {
  .products_heading h1 {
    padding: 20px;
    width: 100%;
    font-size: 50px;
  }
}

.products_heading h1::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 25px;
  height: 25px;
  background-image: url("../img/products/emolient_arrow_left.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

.products_heading h1::after {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  width: 25px;
  height: 25px;
  background-image: url("../img/products/emolient_arrow_right.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center center;
}

.products_heading h1 span {
  display: block;
  font-family: 'Raleway', sans-serif;
  font-size: 37px;
  font-weight: 600;
  color: #4374a6;
  line-height: 24px;
}

@media (max-width: 575px) {
  .products_heading h1 span {
    font-size: 20px;
  }
}

.products_heading h2 {
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #e93d8f;
}

.products_heading p {
  font-family: 'Ubuntu', sans-serif;
  font-size: 13px;
  font-weight: 300;
  color: #3f3e42;
  line-height: 20px;
}

.product_info {
  margin-bottom: 80px;
}

@media (max-width: 575px) {
  .product_info {
    margin-bottom: 40px;
  }
}

.product_info.smaller-container .container {
  /* @include media-breakpoint-down (md) -up {
                width: 1015px;
            } */
}

@media (max-width: 575px) {
  .product_info .product_image img {
    width: 100%;
  }
}

.product_info .info {
  padding-top: 74px;
}

@media (max-width: 575px) {
  .product_info .info {
    padding-top: 0;
  }
}

.product_info .info h3 {
  font-family: 'Raleway', sans-serif;
  font-size: 40px;
  font-weight: 700;
  color: #3f3e42;
  line-height: 33px;
  text-transform: initial;
}

.product_info .info h3 span {
  display: block;
  font-family: 'Raleway', sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: #4374a6;
  line-height: 33px;
}

.product_info .info.no-padding p {
  padding-right: 20px;
}

.product_info .info p {
  color: #2e3033;
  font-family: 'Ubuntu', sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  padding-right: 101px;
}

@media (max-width: 575px) {
  .product_info .info p {
    padding-right: 0px;
  }
}

.product_info .info p span {
  display: inline-block;
  font-family: 'Ubuntu', sans-serif;
  font-size: 13px;
  font-weight: 600;
  color: #e93d8f;
}

.product_info .info span {
  font-family: 'Ubuntu', sans-serif;
  font-size: 13px;
  font-weight: 300;
  line-height: 18px;
  display: block;
  color: #e93d8f;
}

.product_info .info a.more {
  display: block;
  width: 170px;
  height: 35px;
  background-color: #4374a6;
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  padding-top: 8px;
  margin-top: 26px;
  border: 1px solid #4374a6;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

@media (max-width: 575px) {
  .product_info .info a.more {
    width: 100%;
    margin: auto;
    margin-top: 26px;
  }
}

.product_info .info a.more:hover {
  text-decoration: none;
  background-color: white;
  color: #4374a6;
}

.product_info .info a.more:focus {
  text-decoration: none;
  background-color: white;
  color: #4374a6;
}

.products {
  margin-bottom: 35px;
}

@media (max-width: 575px) {
  .products {
    margin-bottom: 20px;
  }
}

.products .container > .row {
  display: flex;
}

@media (max-width: 575px) {
  .products .container > .row {
    display: block;
  }
}

.products a.more {
  display: block;
  width: 170px;
  height: 35px;
  background-color: #4374a6;
  color: white;
  font-family: 'Ubuntu', sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  padding-top: 8px;
  margin-top: 26px;
  border: 1px solid #4374a6;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

@media (max-width: 575px) {
  .products a.more {
    width: 100%;
    margin: auto;
    margin-top: 26px;
  }
}

.products a.more:hover {
  text-decoration: none;
  background-color: white;
  color: #4374a6;
}

.products a.more:focus {
  text-decoration: none;
  background-color: white;
  color: #4374a6;
}

.products h3 {
  font-family: 'Raleway', sans-serif;
  font-size: 24px;
  font-weight: 700;
  color: #3f3e42;
  line-height: 23px;
  margin-top: 37px;
  margin-bottom: 24px;
}

.products h3 span {
  display: block;
  font-family: 'Raleway', sans-serif;
  font-size: 19px;
  font-weight: 700;
  color: #4374a6;
  line-height: 23px;
  height: auto;
}

.products p {
  color: #2e3033;
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
}

@media (max-width: 575px) {
  .products p {
    padding-right: 0px;
  }
}

.products p span {
  display: inline-block;
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #e93d8f;
}

.products span {
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-weight: 300;
  display: block;
  color: #e93d8f;
}

.products .left-prod {
  display: flex;
  margin-bottom: 40px;
  float: left;
}

@media (max-width: 575px) {
  .products .left-prod {
    display: block;
    width: 100%;
    margin-bottom: 40px;
  }
}

.products .left-prod .cont {
  float: left;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 22px;
  padding-bottom: 22px;
  background-color: #f1f9fe;
  background-image: url("../img/products/olejek.png");
  background-repeat: no-repeat;
  background-position: left bottom;
}

@media (max-width: 575px) {
  .products .left-prod .cont {
    padding: 20px;
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 575px) {
  .products .left-prod .cont .image img {
    width: 100%;
  }
}

.products .right-prod {
  display: flex;
  margin-bottom: 40px;
  float: left;
}

@media (max-width: 575px) {
  .products .right-prod {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
}

.products .right-prod .cont {
  float: left;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 22px;
  padding-bottom: 22px;
  background-color: #f1f9fe;
  background-image: url("../img/products/woda.png");
  background-repeat: no-repeat;
  background-position: left bottom;
}

@media (max-width: 575px) {
  .products .right-prod .cont {
    padding: 20px;
    text-align: center;
    width: 100%;
  }
}

@media (max-width: 575px) {
  .products .right-prod .cont .image img {
    width: 100%;
  }
}

.why_atoderm_table {
  background-image: url("../img/products/table_bg.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  padding-bottom: 30px;
}

.why_atoderm_table.smaller-container .container {
  /* @include media-breakpoint-down (md) -up {
                width: 1015px;
            } */
}

.why_atoderm_table .table-responsive {
  border: none !important;
}

@media (max-width: 575px) {
  .why_atoderm_table .table-responsive {
    box-shadow: inset -15px 0 43px -26px #000;
  }
}

.why_atoderm_table p {
  font-family: 'Ubuntu', sans-serif;
  font-size: 10px;
  font-weight: 300;
  color: #2e3033;
  margin: 0;
  line-height: 18px;
}

.why_atoderm_table table {
  margin-bottom: 56px !important;
  z-index: -1;
  position: relative;
}

.why_atoderm_table table thead tr th {
  border-bottom: 2px solid #e93d8f;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #e93d8f;
  text-align: center;
}

.why_atoderm_table table thead tr th h3 {
  margin: 0;
  padding: 0;
  text-align: left;
  font-family: 'Ubuntu', sans-serif;
  font-size: 25px;
  font-weight: 400;
  color: #e93d8f;
}

.why_atoderm_table table thead tr th h3 span {
  font-family: 'Ubuntu', sans-serif;
  font-size: 25px;
  font-weight: 700;
  color: #4374a6;
}

.why_atoderm_table table tbody {
  margin-top: 11px;
}

.why_atoderm_table table tbody tr:nth-of-type(odd) {
  background-color: white;
}

.why_atoderm_table table tbody tr:nth-of-type(odd) td {
  border: none;
  border-right: 30px solid white;
  background-color: white;
}

@media (max-width: 575px) {
  .why_atoderm_table table tbody tr:nth-of-type(odd) td {
    border: none;
  }
}

.why_atoderm_table table tbody tr:nth-of-type(odd) td:last-of-type {
  border-right: 0px;
}

.why_atoderm_table table tbody tr td {
  border: none;
  background-color: #eff5f9;
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 17px;
  padding-right: 17px;
  font-family: 'Ubuntu', sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #2e3033;
  position: relative;
  border-right: 30px solid white;
  min-width: 160px;
}

@media (max-width: 575px) {
  .why_atoderm_table table tbody tr td {
    border: none;
  }
}

.why_atoderm_table table tbody tr td:last-of-type {
  border-right: 0px;
}

.why_atoderm_table table tbody tr td .okay {
  width: 20px;
  height: 16px;
  position: absolute;
  background-image: url("../img/products/okay.png");
  margin: auto;
  left: 0;
  right: 0;
}

.specialists-contact__modal-content {
  border: 0;
  background-color: #f0f5f9;
}

.specialists-contact__modal-header {
  padding: 15px 15px 0 15px;
  border-bottom: 0;
  text-align: center;
}

.specialists-contact__icon-close {
  margin-top: -15px !important;
  margin-right: -7px;
  color: #e93c8f;
  font-size: 32px;
  opacity: 1;
  transition: all 0.25s;
}

.specialists-contact__icon-close:hover {
  color: #0775a6;
  opacity: 1;
}

.specialists-contact__title {
  margin-top: 15px;
  color: #0775a6;
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: unset;
}

.specialists-contact__title span {
  font-weight: 700;
}

.specialists-contact__input {
  margin-bottom: 10px;
  color: #143e6f;
  box-shadow: none;
  -webkit-appearance: none;
}

.specialists-contact__input--textarea {
  margin-bottom: 24px;
  resize: none;
  -webkit-appearance: none;
}

.specialists-contact__input::placeholder {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #143e6f;
}

.specialists-contact__input:active, .specialists-contact__input:focus {
  box-shadow: none;
  border-color: #0775a6;
}

.specialists-contact__input-error-message {
  color: #f00 !important;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
  transition: all 0.25s;
  opacity: 0;
}

.specialists-contact__input-error-message--inside-input {
  width: 220px !important;
}

.specialists-contact__input-error-message--hide {
  display: none;
}

.specialists-contact__input-error-message--show {
  opacity: 1;
}

.specialists-contact__checkbox-input {
  opacity: 0;
  position: absolute;
}

.specialists-contact__checkbox-input + label {
  opacity: 1;
  position: relative;
  margin: 0;
  padding-left: 15px;
  cursor: pointer;
}

.specialists-contact__checkbox-input + label::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 0;
  height: 11px;
  width: 11px;
  border: 1px solid #bebebe;
  background-color: #fff;
}

.specialists-contact__checkbox-input + label::after {
  content: '';
  opacity: 0;
  position: absolute;
  top: 6px;
  left: 2px;
  height: 7px;
  width: 7px;
  padding-left: 1px;
  background-color: #e93c8f;
  transition: all 0.25s;
}

.specialists-contact__checkbox-input:checked + label::after {
  opacity: 1;
}

.specialists-contact__checkbox-input-label {
  font-family: "Raleway", sans-serif;
  font-size: 11px;
  font-weight: 400;
  color: #143e6f;
}

.specialists-contact__checkbox-input-label:hover {
  cursor: pointer;
}

.specialists-contact__checkbox-input-label--link {
  color: #e93c8f;
  font-weight: 700;
  text-decoration: none;
  transition: all 0.25s;
}

.specialists-contact__checkbox-input-label--link:active, .specialists-contact__checkbox-input-label--link:focus {
  color: #e93c8f;
  text-decoration: none;
}

.specialists-contact__checkbox-input-label--link:hover {
  color: #0775a6;
}

.specialists-contact__send-btn {
  padding: 12px 30px;
  border: 0;
  border-radius: 8px;
  color: #fff;
  background-color: #e93c8f;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  transition: all 0.5s;
}

.specialists-contact__send-btn:hover {
  color: #fff;
  background-color: #0775a6;
}

.specialists-contact__modal-footer {
  padding: 5px 15px;
  border-top: 0;
  text-align: left;
}

.specialists-contact__regulations-text, .specialists-contact__regulations-list {
  margin-bottom: 3px;
  color: #143e6f;
  font-family: "Raleway", sans-serif;
  font-size: 11px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: -0.02em;
}

.specialists-contact__regulations-text--list, .specialists-contact__regulations-list--list {
  padding-inline-start: 10px;
}

.specialists-contact__regulations-text--list li, .specialists-contact__regulations-list--list li {
  margin-bottom: 3px;
}

.specialists-contact__success-alert {
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background: rgba(244, 248, 251, 0.75);
  opacity: 0;
  transition: all 0.25s;
}

.specialists-contact__success-alert--show {
  display: flex;
  opacity: 1;
  z-index: 5;
}

.specialists-contact__success-alert-text {
  color: #0775a6;
  font-family: "Raleway", sans-serif;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  line-height: unset;
}

.specialists-header {
  margin-bottom: 30px;
  padding: 80px 0 20px 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  /* ====================
     * Moduł wyszukiwarki
     * ==================== */
}

@media (max-width: 991px) {
  .specialists-header {
    padding: 20px 0;
  }
}

.specialists-header__title {
  margin-bottom: 15px;
  height: 70px;
}

@media (max-width: 991px) {
  .specialists-header__title {
    height: auto;
    width: 90%;
  }
}

.specialists-header__text {
  margin-bottom: 55px;
  color: #0775a6;
  font-family: "Raleway", sans-serif;
  font-size: 22px;
  font-weight: 400;
  line-height: 28px;
}

@media (max-width: 991px) {
  .specialists-header__text {
    font-size: 18px;
  }
}

.specialists-header__text--small {
  margin-top: 30px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1;
}

.specialists-header__input-find-group {
  margin-bottom: 15px;
}

.specialists-header__input-find-place-marker {
  padding: 0px 5px 0px 16px !important;
  padding-bottom: 0px;
  border-right: none !important;
  border-top-left-radius: 23px !important;
  border-bottom-left-radius: 23px !important;
  background-color: #fff;
}

@media (max-width: 991px) {
  .specialists-header__input-find-place-marker {
    padding-top: 9px !important;
    padding-bottom: 9px !important;
  }
}

.specialists-header__icon-map-marker::before {
  color: #e93c8f;
}

.specialists-header__input-find {
  border-left-width: 0;
  border-right-width: 0;
  color: #143e6f;
  box-shadow: none;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #143e6f;
  -webkit-appearance: none;
}

.specialists-header__input-find::placeholder {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #143e6f;
}

.specialists-header__input-find:hover {
  left: -1px;
  width: calc(100% + 1px);
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #0775a6;
}

.specialists-header__input-find:active, .specialists-header__input-find:focus {
  left: -1px;
  width: calc(100% + 1px);
  border-left-width: 1px;
  border-right-width: 1px;
  box-shadow: none;
  border-color: #e93c8f;
}

.specialists-header__btn-right-group {
  padding-right: 115px;
}

@media (max-width: 991px) {
  .specialists-header__btn-right-group {
    padding-right: 0;
  }
}

.specialists-header__btn-locate {
  width: 30px;
  margin-left: 0 !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  border-left-width: 0;
  border-right-width: 0;
  transition: all 0.25s;
}

.specialists-header__btn-locate:hover {
  width: 30px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #0775a6;
  background-color: #fff;
}

.specialists-header__icon-locate::before {
  color: #e93c8f;
  transition: all 0.25s;
}

.specialists-header__btn-spacer {
  margin-left: 0 !important;
  padding: 0 !important;
  border-left: 0px;
  border-right: 0px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  background-color: #fff;
  color: #143e6f;
}

.specialists-header__btn-spacer:hover, .specialists-header__btn-spacer:active, .specialists-header__btn-spacer:focus {
  color: #143e6f;
  cursor: unset;
}

.specialists-header__btn-spacer span {
  color: #ececec;
}

.specialists-header__input-mileage {
  display: inline-block !important;
  padding: 6px 0;
  height: 46px;
  width: 35px !important;
  float: none !important;
  border-left-width: 0;
  border-right-width: 0;
  box-shadow: unset;
  vertical-align: middle;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #143e6f;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

.specialists-header__input-mileage ::-webkit-outer-spin-button,
.specialists-header__input-mileage ::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.specialists-header__input-mileage::-webkit-outer-spin-button, .specialists-header__input-mileage::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.specialists-header__input-mileage::placeholder {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #143e6f;
}

.specialists-header__input-mileage:hover {
  border-left-width: 1px;
  border-right-width: 1px;
  border-color: #0775a6;
}

.specialists-header__input-mileage:active, .specialists-header__input-mileage:focus {
  border-left-width: 1px;
  border-right-width: 1px;
  box-shadow: none;
  border-color: #e93c8f;
}

.specialists-header__btn-mileage {
  margin-left: 0px !important;
  padding-left: 5px !important;
  border-left: 0;
  border-top-right-radius: 23px !important;
  border-bottom-right-radius: 23px !important;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  color: #143e6f;
  background-color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  font-weight: 400;
  transition: all 0.25s;
}

.specialists-header__btn-mileage:hover, .specialists-header__btn-mileage:active, .specialists-header__btn-mileage:focus {
  cursor: unset;
}

@media (max-width: 991px) {
  .specialists-header__btn-mileage {
    border-right: 1px solid #ccc;
  }
}

.specialists-header .tooltip > .tooltip-inner {
  border-radius: 0;
  color: #fff;
  background-color: #0775a6;
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-transform: lowercase;
}

.specialists-header .tooltip > .tooltip-arrow {
  color: #fff;
  border-bottom-color: #0775a6;
}

.specialists-header__btn-search {
  position: absolute !important;
  right: 0;
  padding-left: 50px !important;
  border-top-right-radius: 23px !important;
  border-bottom-right-radius: 23px !important;
  background-color: #e93c8f;
  color: #fff;
  text-transform: uppercase;
  transition: all 0.5s;
  z-index: 1 !important;
}

.specialists-header__btn-search:hover, .specialists-header__btn-search:active, .specialists-header__btn-search:focus {
  color: #fff;
  background-color: #0775a6;
}

@media (max-width: 991px) {
  .specialists-header__btn-search--desktop {
    display: none;
  }
}

.specialists-header__btn-search--mobile {
  display: none;
}

@media (max-width: 991px) {
  .specialists-header__btn-search--mobile {
    display: inline-block;
    position: relative !important;
    margin: 20px 0;
    padding-left: 20px !important;
    border-radius: 23px;
    font-size: 22px;
  }
}

.specialists-header__icon-magnifier::before {
  color: #fff;
}

.specialists-header__checkbox-group {
  display: inline-block;
  margin: 0 3px;
  padding: 6px 12px;
  border: 1px solid #eaeaea;
  border-radius: 18px;
  background-color: #fff;
  transition: all 0.25s;
}

.specialists-header__checkbox-group:hover {
  border-color: #0775a6;
}

@media (max-width: 991px) {
  .specialists-header__checkbox-group {
    margin-top: 5px;
  }
}

.specialists-header__checkbox-input {
  opacity: 0;
  position: absolute;
}

.specialists-header__checkbox-input + label {
  opacity: 1;
  position: relative;
  margin: 0;
  padding-left: 15px;
  cursor: pointer;
}

.specialists-header__checkbox-input + label::before {
  content: '';
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 0;
  height: 11px;
  width: 11px;
  border: 1px solid #bebebe;
  background-color: #fff;
}

.specialists-header__checkbox-input + label::after {
  content: '';
  opacity: 0;
  position: absolute;
  top: 6px;
  left: 2px;
  height: 7px;
  width: 7px;
  padding-left: 1px;
  background-color: #e93c8f;
  transition: all 0.25s;
}

.specialists-header__checkbox-input:checked + label::after {
  opacity: 1;
}

.specialists-header__checkbox-input:disabled + label {
  cursor: not-allowed;
}

.specialists-header__checkbox-input:disabled + label::before {
  background-color: #d8d8d8;
}

.specialists-header__checkbox-input-label {
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #0775a6;
  text-transform: uppercase;
}

.specialists-header__checkbox-input-label:hover {
  cursor: pointer;
}

.specialists-header__error-message {
  border-color: #143e6f;
  background-color: #f4f8fb;
  color: #0775a6;
  font-family: "Raleway", sans-serif;
  font-weight: 700;
}

.specialists-map {
  /* ===================
     * Dymek od znacznika
     * =================== */
  /* ===================
     * Nawigacja
     * =================== */
  /* ===================
     * Legenda
     * =================== */
  /* ===================
     * Kontakt
     * =================== */
}

.specialists-map--results-section {
  margin-bottom: 35px;
}

.specialists-map__text {
  margin-bottom: 30px;
  font-family: "Raleway", sans-serif;
  font-size: 13px;
  font-weight: 400;
  color: #0775a6;
}

.specialists-map__logo-ptca {
  float: left;
  margin-right: 25px;
}

.specialists-map__map-box {
  padding: 0;
  overflow: hidden;
}

@media (max-width: 991px) {
  .specialists-map__map-box--searching {
    min-height: 330px;
  }
}

.specialists-map__map {
  height: 740px;
}

@media (max-width: 991px) {
  .specialists-map__map {
    display: none;
  }
}

.specialists-map__panel-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  pointer-events: none;
}

@media (max-width: 991px) {
  .specialists-map__panel-box {
    position: relative;
  }
}

.specialists-map__panel {
  margin-top: 1px;
  padding-top: 10px;
  pointer-events: all;
  background-color: #fff;
}

@media (max-width: 767px) {
  .specialists-map__panel {
    margin-top: 0;
  }
}

.specialists-map__panel-title {
  margin-bottom: 5px;
  font-family: 'Ubuntu', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #143e6f;
  text-transform: uppercase;
}

.specialists-map__panel-scroller {
  height: 704px;
}

@media (max-width: 991px) {
  .specialists-map__panel-scroller {
    height: 450px;
  }
}

.specialists-map__panel-scroller--smaller {
  min-height: 160px;
  height: auto;
}

.specialists-map__panel-scroller--hide {
  display: none;
}

.specialists-map__panel-item {
  margin: 0 0 2px 0;
  padding: 5px;
  border: 1px solid #e1e1e1;
  transition: border-color 0.25s;
}

.specialists-map__panel-item:hover {
  cursor: pointer;
  border-color: #aaa;
}

@media (max-width: 1199px) {
  .specialists-map__panel-item {
    display: flex;
    flex-flow: row;
  }
}

.specialists-map__panel-item-left-icons {
  border-right: 3px solid #333;
}

@media (max-width: 1199px) {
  .specialists-map__panel-item-left-icons {
    float: left;
    width: 20%;
    /* @supports (-webkit-overflow-scrolling: touch) {
                height: auto;
            } */
  }
}

.specialists-map__panel-item-left-icons--doctor {
  border-right-color: #e93c8f;
  color: #e93c8f;
}

.specialists-map__panel-item-left-icons--psychologist {
  border-right-color: #53bdec;
  color: #53bdec;
}

.specialists-map__panel-item-left-icons--pharmacy {
  border-right-color: #0775a6;
  color: #0775a6;
}

.specialists-map__panel-item-left-icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.specialists-map__panel-item-left-icon::before {
  font-size: 36px;
}

.specialists-map__panel-item-left-icon--top {
  top: 0;
}

.specialists-map__panel-item-left-icon--bottom {
  bottom: 0;
}

.specialists-map__panel-item-left-icon--bottom::before {
  font-size: 24px;
}

@media (max-width: 1199px) {
  .specialists-map__panel-item-right {
    float: left;
    width: 80%;
  }
}

.specialists-map__panel-item-text {
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #143e6f;
}

.specialists-map__panel-item-text--smaller {
  font-size: 12px;
  line-height: 14px;
}

.specialists-map__panel-item-text--bolder {
  font-weight: 700;
}

.specialists-map__panel-item-text--margin-top {
  margin-top: 15px;
}

.specialists-map__panel-item-text--padding-right {
  padding-right: 30px;
}

.specialists-map__panel-item-link {
  color: #143e6f;
}

.specialists-map__panel-item-content-icon {
  position: absolute;
  top: 5px;
  right: 0;
}

.specialists-map__panel-item-content-icon--zsoa:before {
  color: #e93c8f;
  font-size: 26px;
}

.specialists-map__panel-item-content-icon--nfz:before {
  color: #2e3192;
  font-size: 16px;
}

.specialists-map .gm-style-iw {
  max-width: 240px !important;
  border-radius: 0 !important;
}

.specialists-map__pointer-info {
  max-width: 250px;
  padding: 15px 30px;
}

.specialists-map__pointer-info-text {
  margin-bottom: 0;
  color: #0775a6;
  font-family: "Raleway", sans-serif;
  font-size: 12px;
  font-weight: 400;
}

.specialists-map__pointer-info-text--distance {
  color: #143e6f;
}

.specialists-map__pointer-info-text--title {
  font-size: 14px;
  font-weight: 700;
}

.specialists-map__pointer-info-text--address-top {
  margin-top: 15px;
}

.specialists-map__pointer-info-text--address-bottom {
  margin-bottom: 10px;
}

.specialists-map__pointer-info-spacer {
  margin: 10px 0;
  border-top: 1px solid rgba(233, 60, 143, 0.25);
}

.specialists-map__pointer-info-button {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 25px;
  border: 0;
  background-color: #143e6f;
  color: #fff;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  transition: all 0.25s;
}

.specialists-map__pointer-info-button:last-child {
  margin-bottom: 0;
}

.specialists-map__pointer-info-button:hover, .specialists-map__pointer-info-button:focus, .specialists-map__pointer-info-button:active {
  color: #fff;
  background-color: #e93c8f;
  text-decoration: none;
}

.specialists-map__nav {
  position: absolute;
  right: 20px;
  bottom: 20px;
}

@media (max-width: 767px) {
  .specialists-map__nav {
    display: none;
  }
}

.specialists-map__nav-btn {
  display: block;
  height: 40px;
  width: 40px;
  margin-bottom: 10px;
  border: 0;
  color: #fff;
  background-color: #e93c8f;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.5s;
}

.specialists-map__nav-btn:hover {
  color: #fff;
  background-color: #0775a6;
}

.specialists-map__nav-btn-icon:before {
  font-size: 28px;
}

.specialists-map__legend-item {
  display: flex;
  margin: 0 0 10px 0;
  padding: 16px 50px 16px 0;
  background-color: #f0f5f9;
}

.specialists-map__legend-item:last-of-type {
  margin-bottom: 0;
}

@media (max-width: 991px) {
  .specialists-map__legend-item {
    display: block;
    padding: 15px;
  }
}

.specialists-map__legend-icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 991px) {
  .specialists-map__legend-icon-box {
    float: left;
    width: 30%;
  }
}

.specialists-map__legend-icon::before {
  font-size: 36px;
}

.specialists-map__legend-icon--coupon-zsoa::before {
  color: #e93c8f;
}

.specialists-map__legend-icon--psychologist::before {
  color: #53bdec;
}

.specialists-map__legend-icon--pharmacy::before {
  color: #0775a6;
}

.specialists-map__legend-icon--doctor::before {
  color: #e93c8f;
}

.specialists-map__legend-text-box {
  display: flex;
  align-items: center;
}

@media (max-width: 991px) {
  .specialists-map__legend-text-box {
    float: left;
    width: 70%;
  }
}

.specialists-map__legend-item-text {
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: #0775a6;
}

.specialists-map__contact-box {
  display: flex;
  flex-direction: column;
  background-image: url("../img/map-contact-background.jpg");
  background-position: top left;
  background-repeat: no-repeat;
  text-align: center;
}

@media (max-width: 1199px) {
  .specialists-map__contact-box {
    margin: 0 15px;
    min-height: 260px;
  }
}

@media (max-width: 991px) {
  .specialists-map__contact-box {
    margin: auto;
    min-height: unset;
  }
}

.specialists-map__contact-text-box-row--bottom {
  margin-top: auto;
}

.specialists-map__contact-title {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 30px;
  padding: 20px 30px;
  width: fit-content;
  color: #e93c8f;
  font-family: "Raleway", sans-serif;
  font-size: 36px;
  font-weight: 300;
  text-transform: uppercase;
  line-height: unset;
}

.specialists-map__contact-title:before {
  content: "";
}

.specialists-map__contact-title:after {
  content: "";
}

@supports (-webkit-overflow-scrolling: touch) {
  .specialists-map__contact-title {
    width: 333px;
  }
}

.specialists-map__contact-title::before {
  position: absolute;
  left: 0;
  bottom: 0;
  color: #0775a6;
  transform: rotate(90deg);
}

.specialists-map__contact-title::after {
  position: absolute;
  top: 0;
  right: 0;
  color: #0775a6;
  transform: rotate(-90deg);
}

@media (max-width: 991px) {
  .specialists-map__contact-title {
    position: relative;
    margin-bottom: 30px;
  }
}

.specialists-map__contact-title span {
  color: #0775a6;
  font-weight: 700;
}

.specialists-map__contact-text-box {
  margin-bottom: 25px;
}

.specialists-map__contact-text-box--right {
  border-left: 2px solid #0775a6;
}

@media (max-width: 991px) {
  .specialists-map__contact-text-box--right {
    padding-top: 20px;
    border-top: 2px solid #0775a6;
    border-left: 0;
  }
}

.specialists-map__contact-text {
  margin-bottom: 0;
  color: #0775a6;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.specialists-map__contact-button {
  padding: 12px 30px;
  border-radius: 0;
  color: #fff;
  background-color: #e93c8f;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.5s;
}

.specialists-map__contact-button:hover {
  color: #fff;
  background-color: #0775a6;
}

.specialists-promo {
  /* &__logo-ptca {
        float: left;
        margin-right: 25px;
    } */
}

.specialists-promo__row {
  margin-bottom: 30px;
}

.specialists-promo__text-box {
  padding: 30px 85px;
  background-color: #f4f8fb;
}

@media (max-width: 991px) {
  .specialists-promo__text-box {
    padding: 15px;
  }
}

.specialists-promo__text-box--spacer {
  padding: 80px 85px;
}

@media (max-width: 991px) {
  .specialists-promo__text-box--spacer {
    padding: 15px;
  }
}

@media (max-width: 767px) {
  .specialists-promo__text-box--right {
    width: 100%;
  }
}

.specialists-promo__text {
  margin-bottom: 10px;
  font-family: "Raleway", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #0775a6;
}

.specialists-promo__text--spacer {
  padding: 20px 0;
}

.specialists-promo__text--smaller {
  font-size: 13px;
}

.specialists-promo__link {
  text-decoration: underline;
}

.specialists-promo__image-container {
  position: relative;
  overflow: hidden;
}

@media (max-width: 991px) {
  .specialists-promo__image-container {
    height: 280px;
    width: 100%;
  }
}

.specialists-promo__image-container--left {
  padding-right: 0;
}

@media (max-width: 767px) {
  .specialists-promo__image-container--left {
    width: 100%;
  }
}

.specialists-promo__image-container--right {
  padding-left: 0;
}

.specialists-promo__image-container--mobile {
  display: none;
}

@media (max-width: 991px) {
  .specialists-promo__image-container--mobile {
    display: block;
  }
}

@media (max-width: 991px) {
  .specialists-promo__image-container--desktop {
    display: none;
  }
}

.specialists-promo__image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 1199px) {
  .specialists-promo__image {
    height: 100%;
  }
}

.specialists-searching {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.75);
  text-align: center;
  z-index: 999;
}

.specialists-searching__content-box--error {
  padding: 50px;
  border: 3px solid #e93c8f;
  background-color: rgba(255, 255, 255, 0.9);
}

.specialists-searching__icon-cog-box--animate {
  animation: rotating 2s linear infinite;
}

.specialists-searching__icon-cog {
  vertical-align: sub;
}

.specialists-searching__icon-cog::before {
  color: #e93c8f;
  font-size: 82px;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.specialists-searching__text {
  margin-top: 50px;
  margin-bottom: 0;
  font-family: "Raleway", sans-serif;
  font-size: 48px;
  font-weight: 700;
  color: #e93c8f;
  line-height: normal;
}

@media (max-width: 991px) {
  .specialists-searching__text--error {
    margin-top: 25px;
    font-size: 20px;
  }
}

.specialists-searching__text--animate:after {
  content: ' .';
  animation: dots 2s steps(5, end) infinite;
}

@keyframes dots {
  0%,
  20% {
    color: rgba(0, 0, 0, 0);
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  40% {
    color: #e93c8f;
    text-shadow: 0.25em 0 0 rgba(0, 0, 0, 0), 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  60% {
    text-shadow: 0.25em 0 0 #e93c8f, 0.5em 0 0 rgba(0, 0, 0, 0);
  }
  80%,
  100% {
    text-shadow: 0.25em 0 0 #e93c8f, 0.5em 0 0 #e93c8f;
  }
}
