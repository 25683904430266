
/// Style dla sekcji nawigacyjnej. Jest to przeniesienie starego ostylowania LESS na Gulp'a.
// UWAGA: NIE JEST TO PEŁEN STYL, A JEDYNIE NADPISANIE WYMAGAJĄCYCH MODYFIKACJI REGUŁ.
/// @name Nav
/// @group Components
nav {
    ul.nav {
        > li {
            &.logo{
                margin-left: 50px;
                margin-right: 50px;

                @include media-breakpoint-down (xl) {
                    margin-left: 30px;
                    margin-right: 30px
                }
                @include media-breakpoint-down (lg) {
                    margin-left: 10px;
                    margin-right: 10px
                }
                @include media-breakpoint-down (xs) {
                    margin: 0;
                }
            }
        }
    }
}
