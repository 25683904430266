// @import 'iconfont';
@import 'mixins';

/**
 * Czcionki
 * @include font-face({font-name}, '../fonts/{font-folder}/{font-file-name}', {font-weight}, {font-style}, {font-type: woff ttf woff2});
 */

@include font-face('Raleway', '../fonts/Raleway/raleway-regular', 400, normal, woff ttf woff2);
// @include font-face('Ubuntu', '../fonts/Ubuntu/ubuntu-regular', 400, normal, woff ttf woff2);
