
/// Style dla modala z formularzem kontaktowym.
/// @name Contact
/// @group specialists
.specialists-contact {
    &__modal-content {
        border: 0;
        background-color: #f0f5f9;
    }

    &__modal-header {
        padding: 15px 15px 0 15px;
        border-bottom: 0;
        text-align: center;
    }

    &__icon-close {
        margin-top: -15px !important;
        margin-right: -7px;
        color: $color-second;
        font-size: 32px;
        opacity: 1;
        transition: all 0.25s;

        &:hover {
            color: $color-first;
            opacity: 1;
        }
    }

    &__title {
        margin-top: 15px;
        color: $color-first;
        font-family: $default-font;
        font-size: 24px;
        font-weight: 400;
        line-height: unset;

        span {
            font-weight: 700;
        }
    }

    &__input {
        margin-bottom: 10px;
        color: $color-first-dark;
        box-shadow: none;
        -webkit-appearance: none;

        &--textarea {
            margin-bottom: 24px;
            resize: none;
            -webkit-appearance: none;
        }

        &::placeholder {
            font-family: $default-font;
            font-size: 16px;
            font-weight: 400;
            color: $color-first-dark;
        }

        &:active,
        &:focus {
            box-shadow: none;
            border-color: $color-first;
        }
    }

    &__input-error-message {
        color: #f00 !important;
        font-family: $default-font;
        font-weight: 700;
        transition: all 0.25s;
        opacity: 0;

        &--inside-input {
            width: 220px !important;
        }

        &--hide {
            display: none;
        }

        &--show {
            opacity: 1;
        }
    }

    &__checkbox-input {
        opacity: 0;
        position: absolute;

        + label {
            opacity: 1;
            position: relative;
            margin: 0;
            padding-left: 15px;
            cursor: pointer;

            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 4px;
                left: 0;
                height: 11px;
                width: 11px;
                border: 1px solid #bebebe;
                background-color: #fff;
            }

            &::after {
                content: '';
                opacity: 0;
                position: absolute;
                top: 6px;
                left: 2px;
                height: 7px;
                width: 7px;
                padding-left: 1px;
                background-color: $color-second;
                transition: all 0.25s;
            }
        }

        &:checked + label {
            &::after {
                opacity: 1;
            }
        }
    }

    &__checkbox-input-label {
        font-family: $default-font;
        font-size: 11px;
        font-weight: 400;
        color: $color-first-dark;

        &:hover {
            cursor: pointer;
        }

        &--link {
            color: $color-second;
            font-weight: 700;
            text-decoration: none;
            transition: all 0.25s;

            &:active,
            &:focus {
                color: $color-second;
                text-decoration: none;
            }

            &:hover {
                color: $color-first;
            }
        }
    }

    &__send-btn {
        padding: 12px 30px;
        border: 0;
        border-radius: 8px;
        color: #fff;
        background-color: $color-second;
        font-family: $default-font;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
        transition: all 0.5s;

        &:hover {
            color: #fff;
            background-color: $color-first;
        }
    }

    &__modal-footer {
        padding: 5px 15px;
        border-top: 0;
        text-align: left;
    }

    &__regulations-text,
    &__regulations-list {
        margin-bottom: 3px;
        color: $color-first-dark;
        font-family: $default-font;
        font-size: 11px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: -0.02em;

        &--list {
            padding-inline-start: 10px;

            li {
                margin-bottom: 3px;
            }
        }
    }

    &__success-alert {
        position: absolute;
        top: 30px; // Miejsce na X zamykający okno.
        left: 0;
        right: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
        background: rgba(244, 248, 251, 0.75);
        opacity: 0;
        transition: all 0.25s;

        &--show {
            display: flex;
            opacity: 1;
            z-index: 5;
        }
    }

    &__success-alert-text {
        color: $color-first;
        font-family: $default-font;
        font-size: 24px;
        font-weight: 400;
        text-align: center;
        line-height: unset;
    }
}
