
/// Style dla mapy z  wynikami wyszukiwania.
/// @name Map
/// @group specialists
.specialists-map {
    &--results-section {
        margin-bottom: 35px;
    }

    &__text {
        margin-bottom: 30px;
        font-family: $default-font;
        font-size: 13px;
        font-weight: 400;
        color: $color-first;
    }

    &__logo-ptca {
        float: left;
        margin-right: 25px;
    }

    &__map-box {
        padding: 0;
        overflow: hidden;

        &--searching {
            @include media-breakpoint-down (md) {
                min-height: 330px;
            }
        }
    }

    &__map {
        height: 740px;

        @include media-breakpoint-down (md) {
            display: none;
        }
    }

    &__panel-box {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 2;
        pointer-events: none;

        @include media-breakpoint-down (md) {
            position: relative;
        }
    }

    &__panel {
        margin-top: 1px;
        padding-top: 10px;
        pointer-events: all;
        background-color: #fff;

        @include media-breakpoint-down (sm) {
            margin-top: 0;
        }
    }

    &__panel-title {
        margin-bottom: 5px;
        font-family: 'Ubuntu', sans-serif;
        font-size: 16px;
        font-weight: 400;
        color: $color-first-dark;
        text-transform: uppercase;
    }

    &__panel-scroller {
        height: 704px;

        @include media-breakpoint-down (md) {
            height: 450px;
        }

        &--smaller {
            min-height: 160px;
            height: auto;
        }

        &--hide {
            display: none;
        }
    }

    &__panel-item {
        margin: 0 0 2px 0;
        padding: 5px;
        border: 1px solid #e1e1e1;
        transition: border-color 0.25s;

        &:hover {
            cursor: pointer;
            border-color: #aaa;
        }

        @include media-breakpoint-down (lg) {
            display: flex;
            flex-flow: row;
        }
    }

    &__panel-item-left-icons {
        border-right: 3px solid #333;

        @include media-breakpoint-down (lg) {
            float: left;
            width: 20%;

            /* @supports (-webkit-overflow-scrolling: touch) {
                height: auto;
            } */
        }

        &--doctor {
            border-right-color: $color-second;
            color: $color-second;
        }

        &--psychologist {
            border-right-color: $color-first-light;
            color: $color-first-light;
        }

        &--pharmacy {
            border-right-color: $color-first;
            color: $color-first;
        }
    }

    &__panel-item-left-icon {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        &::before {
            font-size: 36px;
        }

        &--top {
            top: 0;
        }

        &--bottom {
            bottom: 0;

            &::before {
                font-size: 24px;
            }
        }
    }

    &__panel-item-right {
        @include media-breakpoint-down (lg) {
            float: left;
            width: 80%;
        }
    }

    &__panel-item-text {
        margin-bottom: 0;
        font-family: $default-font;
        font-size: 14px;
        font-weight: 400;
        color: $color-first-dark;

        &--smaller {
            font-size: 12px;
            line-height: 14px;
        }

        &--bolder {
            font-weight: 700;
        }

        &--margin-top {
            margin-top: 15px;
        }

        &--padding-right {
            padding-right: 30px;
        }
    }

    &__panel-item-link {
        color: $color-first-dark;
    }

    &__panel-item-content-icon {
        position: absolute;
        top: 5px;
        right: 0;

        &--zsoa {
            &:before {
                color: $color-second;
                font-size: 26px;
            }
        }

        &--nfz {
            &:before {
                color: #2e3192;
                font-size: 16px;
            }
        }
    }

    /* ===================
     * Dymek od znacznika
     * =================== */
    .gm-style-iw {
        max-width: 240px !important;
        border-radius: 0 !important;
    }

    &__pointer-info {
        max-width: 250px;
        padding: 15px 30px;
    }

    &__pointer-info-text {
        margin-bottom: 0;
        color: $color-first;
        font-family: $default-font;
        font-size: 12px;
        font-weight: 400;

        &--distance {
            color: $color-first-dark;
        }

        &--title {
            font-size: 14px;
            font-weight: 700;
        }

        &--address-top {
            margin-top: 15px;
        }

        &--address-bottom {
            margin-bottom: 10px;
        }
    }

    &__pointer-info-spacer {
        margin: 10px 0;
        border-top: 1px solid rgba(233, 60, 143, 0.25);
    }

    &__pointer-info-button {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        padding: 10px 25px;
        border: 0;
        background-color: $color-first-dark;
        color: #fff;
        font-family: $default-font;
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        transition: all 0.25s;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover,
        &:focus,
        &:active {
            color: #fff;
            background-color: $color-second;
            text-decoration: none;
        }
    }

    /* ===================
     * Nawigacja
     * =================== */
    &__nav {
        position: absolute;
        right: 20px;
        bottom: 20px;

        @include media-breakpoint-down (sm) {
            display: none;
        }
    }

    &__nav-btn {
        display: block;
        height: 40px;
        width: 40px;
        margin-bottom: 10px;
        border: 0;
        color: #fff;
        background-color: $color-second;
        font-family: $default-font;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        transition: all 0.5s;

        &:hover {
            color: #fff;
            background-color: $color-first;
        }
    }

    &__nav-btn-icon {
        &:before {
            font-size: 28px;
        }
    }

    /* ===================
     * Legenda
     * =================== */
    &__legend-item {
        display: flex;
        margin: 0 0 10px 0;
        padding: 16px 50px 16px 0;
        background-color: #f0f5f9;

        &:last-of-type {
            margin-bottom: 0;
        }

        @include media-breakpoint-down (md) {
            display: block;
            padding: 15px;
        }
    }

    &__legend-icon-box {
        display: flex;
        align-items: center;
        justify-content: center;

        @include media-breakpoint-down (md) {
            float: left;
            width: 30%;
        }
    }

    &__legend-icon {
        &::before {
            font-size: 36px;
        }

        &--coupon-zsoa {
            &::before {
                color: $color-second;
            }
        }

        &--psychologist {
            &::before {
                color: $color-first-light;
            }
        }

        &--pharmacy {
            &::before {
                color: $color-first;
            }
        }

        &--doctor {
            &::before {
                color: $color-second;
            }
        }
    }

    &__legend-text-box {
        display: flex;
        align-items: center;

        @include media-breakpoint-down (md) {
            float: left;
            width: 70%;
        }
    }

    &__legend-item-text {
        margin-bottom: 0;
        font-family: $default-font;
        font-size: 14px;
        font-weight: 700;
        color: $color-first;
    }

    /* ===================
     * Kontakt
     * =================== */
    &__contact-box {
        display: flex;
        flex-direction: column;
        background-image: url('../img/map-contact-background.jpg');
        background-position: top left;
        background-repeat: no-repeat;
        text-align: center;

        @include media-breakpoint-down (lg) {
            margin: 0 15px;
            min-height: 260px;
        }

        @include media-breakpoint-down (md) {
            margin: auto;
            min-height: unset;
        }
    }

    &__contact-text-box-row {
        &--bottom {
            margin-top: auto;
        }
    }

    &__contact-title {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 30px;
        padding: 20px 30px;
        width: fit-content;
        color: $color-second;
        font-family: $default-font;
        font-size: 36px;
        font-weight: 300;
        text-transform: uppercase;
        line-height: unset;

        @include icon(corner, 'before');
        @include icon(corner, 'after');

        // Sztywna szerokość dla iOS Safari =.=
        @supports (-webkit-overflow-scrolling: touch) {
            width: 333px;
        }

        &::before {
            position: absolute;
            left: 0;
            bottom: 0;
            color: $color-first;
            transform: rotate(90deg);
        }

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            color: $color-first;
            transform: rotate(-90deg);
        }

        @include media-breakpoint-down (md) {
            position: relative;
            margin-bottom: 30px;
        }

        span {
            color: $color-first;
            font-weight: 700;
        }
    }

    &__contact-text-box {
        margin-bottom: 25px;

        &--right {
            border-left: 2px solid $color-first;

            @include media-breakpoint-down (md) {
                padding-top: 20px;
                border-top: 2px solid $color-first;
                border-left: 0;
            }
        }
    }

    &__contact-text {
        margin-bottom: 0;
        color: $color-first;
        font-family: $default-font;
        font-size: 14px;
        font-weight: 600;
        text-align: left;
    }

    &__contact-button {
        padding: 12px 30px;
        border-radius: 0;
        color: #fff;
        background-color: $color-second;
        font-family: $default-font;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        transition: all 0.5s;

        &:hover {
            color: #fff;
            background-color: $color-first;
        }
    }
}
