
/// Klasa zawierająca style dla górnej części strony z bazą specjalistów (header + wyszukiwarka)
/// @name Header
/// @group specialists
.specialists-header {
    margin-bottom: 30px;
    padding: 80px 0 20px 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;

    @include media-breakpoint-down (md) {
        padding: 20px 0;
    }

    &__title {
        margin-bottom: 15px;
        height: 70px;

        @include media-breakpoint-down (md) {
            height: auto;
            width: 90%;
        }
    }

    &__text {
        margin-bottom: 55px;
        color: $color-first;
        font-family: $default-font;
        font-size: 22px;
        font-weight: 400;
        line-height: 28px;

        @include media-breakpoint-down (md) {
            font-size: 18px;
        }

        &--small {
            margin-top: 30px;
            margin-bottom: 0;
            font-size: 14px;
            line-height: 1;
        }
    }

    /* ====================
     * Moduł wyszukiwarki
     * ==================== */
    &__input-find-group {
        margin-bottom: 15px;
    }

    &__input-find-place-marker {
        padding: 0px 5px 0px 16px !important;
        padding-bottom: 0px;
        border-right: none !important;
        border-top-left-radius: 23px !important;
        border-bottom-left-radius: 23px !important;
        background-color: #fff;

        @include media-breakpoint-down (md) {
            padding-top: 9px !important;
            padding-bottom: 9px !important;
        }
    }

    &__icon-map-marker {
        &::before {
            color: $color-second;
        }
    }

    &__input-find {
        border-left-width: 0;
        border-right-width: 0;
        color: $color-first-dark;
        box-shadow: none;
        font-family: $default-font;
        font-size: 16px;
        font-weight: 400;
        color: $color-first-dark;
        -webkit-appearance: none;

        &::placeholder {
            font-family: $default-font;
            font-size: 16px;
            font-weight: 400;
            color: $color-first-dark;
        }

        &:hover {
            left: -1px;
            width: calc(100% + 1px);
            border-left-width: 1px;
            border-right-width: 1px;
            border-color: $color-first;
        }

        &:active,
        &:focus {
            left: -1px;
            width: calc(100% + 1px);
            border-left-width: 1px;
            border-right-width: 1px;
            box-shadow: none;
            border-color: $color-second;
        }
    }

    &__btn-right-group {
        padding-right: 115px;

        @include media-breakpoint-down (md) {
            padding-right: 0;
        }
    }

    &__btn-locate {
        width: 30px;
        margin-left: 0 !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
        border-left-width: 0;
        border-right-width: 0;
        transition: all 0.25s;

        &:hover {
            width: 30px;
            border-left-width: 1px;
            border-right-width: 1px;
            border-color: $color-first;
            background-color: #fff;
        }
    }

    &__icon-locate {
        &::before {
            color: $color-second;
            transition: all 0.25s;
        }
    }

    &__btn-spacer {
        margin-left: 0 !important;
        padding: 0 !important;
        border-left: 0px;
        border-right: 0px;
        border-top-color: #ccc;
        border-bottom-color: #ccc;
        background-color: #fff;
        color: $color-first-dark;

        &:hover,
        &:active,
        &:focus {
            color: $color-first-dark;
            cursor: unset;
        }

        span {
            color: #ececec;
        }
    }

    &__input-mileage {
        display: inline-block !important;
        padding: 6px 0;
        height: 46px;
        width: 35px !important;
        float: none !important;
        border-left-width: 0;
        border-right-width: 0;
        box-shadow: unset;
        vertical-align: middle;
        font-family: $default-font;
        font-size: 16px;
        font-weight: 400;
        color: $color-first-dark;
        text-align: center;
        -webkit-appearance: none;
        -moz-appearance: textfield;

        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button { 
            -webkit-appearance: none; 
            margin: 0; 
        }

        &::placeholder {
            font-family: $default-font;
            font-size: 16px;
            font-weight: 400;
            color: $color-first-dark;
        }

        &:hover {
            border-left-width: 1px;
            border-right-width: 1px;
            border-color: $color-first;
        }

        &:active,
        &:focus {
            border-left-width: 1px;
            border-right-width: 1px;
            box-shadow: none;
            border-color: $color-second;
        }
    }

    &__btn-mileage {
        margin-left: 0px !important;
        padding-left: 5px !important;
        border-left: 0;
        border-top-right-radius: 23px !important;
        border-bottom-right-radius: 23px !important;
        border-top-color: #ccc;
        border-bottom-color: #ccc;
        color: $color-first-dark;
        background-color: #fff;
        font-family: $default-font;
        font-size: 16px;
        font-weight: 400;
        transition: all 0.25s;

        &:hover,
        &:active,
        &:focus {
            cursor: unset;
        }

        @include media-breakpoint-down (md) {
            border-right: 1px solid #ccc;
        }
    }

    .tooltip > .tooltip-inner {
        border-radius: 0;
        color: #fff;
        background-color: $color-first;
        font-family: $default-font;
        font-size: 12px;
        font-weight: 400;
        text-transform: lowercase;
    }

    .tooltip > .tooltip-arrow {
        color: #fff;
        border-bottom-color: $color-first;
    }

    &__btn-search {
        position: absolute !important;
        right: 0;
        padding-left: 50px !important;
        border-top-right-radius: 23px !important;
        border-bottom-right-radius: 23px !important;
        background-color: $color-second;
        color: #fff;
        text-transform: uppercase;
        transition: all 0.5s;
        z-index: 1 !important;

        &:hover,
        &:active,
        &:focus {
            color: #fff;
            background-color: $color-first;
        }

        &--desktop {
            @include media-breakpoint-down (md) {
                display: none;
            }
        }

        &--mobile {
            display: none;

            @include media-breakpoint-down (md) {
                display: inline-block;
                position: relative !important;
                margin: 20px 0;
                padding-left: 20px !important;
                border-radius: 23px;
                font-size: 22px;
            }
        }
    }

    &__icon-magnifier {
        &::before {
            color: #fff;
        }
    }

    &__checkbox-group {
        display: inline-block;
        margin: 0 3px;
        padding: 6px 12px;
        border: 1px solid #eaeaea;
        border-radius: 18px;
        background-color: #fff;
        transition: all 0.25s;

        &:hover {
            border-color: $color-first;
        }

        @include media-breakpoint-down (md) {
            margin-top: 5px;
        }
    }

    &__checkbox-input {
        opacity: 0;
        position: absolute;

        + label {
            opacity: 1;
            position: relative;
            margin: 0;
            padding-left: 15px;
            cursor: pointer;

            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                top: 4px;
                left: 0;
                height: 11px;
                width: 11px;
                border: 1px solid #bebebe;
                background-color: #fff;
            }

            &::after {
                content: '';
                opacity: 0;
                position: absolute;
                top: 6px;
                left: 2px;
                height: 7px;
                width: 7px;
                padding-left: 1px;
                background-color: $color-second;
                transition: all 0.25s;
            }
        }

        &:checked + label {
            &::after {
                opacity: 1;
            }
        }

        &:disabled {
            + label {
                cursor: not-allowed;

                &::before {
                    background-color: #d8d8d8;
                }
            }
        }
    }

    &__checkbox-input-label {
        font-family: $default-font;
        font-size: 14px;
        font-weight: 700;
        color: $color-first;
        text-transform: uppercase;

        &:hover {
            cursor: pointer;
        }
    }

    &__error-message {
        border-color: $color-first-dark;
        background-color: #f4f8fb;
        color: $color-first;
        font-family: $default-font;
        font-weight: 700;
    }
}
