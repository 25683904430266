/**
* Plik zawiera modyfikacje oryginalnego Bootstrap + nowe klasy rozszerzające możliwości Bootstrap'a.
*/

button:focus,
button:active {
    outline: none !important;
    box-shadow: none !important;
}

input:focus,
input:active {
    outline: none !important;
    box-shadow: none !important;
}

.row-eq-height {
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 0 1 auto;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.col-eq-height-first {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;

    @include media-breakpoint-down (md) {
        flex: unset;
    }
}

.modal-backdrop {
    background-color: #fff;
}

.has-error .form-control {
    border-color: #f00;
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
    color: #f00;
}
