
/// Style dla sekcji Flexible Video. Jest to przeniesienie starego ostylowania LESS na Gulp'a.
/// @name Video
/// @group flexible
section.poster-video {
    margin-top: 60px;

    @include media-breakpoint-down(xs) {
        margin-top: 40px;
    }

    .video-container {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%; /* 16:9 */
        overflow: hidden;
        background-color: #e2f4fe;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;

        &--low {
            padding-bottom: 30%;
        }

        &.is-playing {
            &:hover {
                .stop-button {
                    top: 50%
                }
            }

            iframe {
                z-index: 1;
            }
        }
    }

    .play-button {
        display: block;
        position: absolute;
        width: 70px;
        height: 120px;
        left: 50%;
        top: 50%;
        margin-left: -35px;
        margin-top: -60px;
        text-align: center;
        z-index: 5;

        @include media-breakpoint-down(xs) {
            margin-top: -35px;
        }

        button {
            left: 0;
            width: 70px;
            height: 70px;
            background: url('../img/video/play.png') no-repeat center center;
            background-size: cover;
            border: none;
            position: absolute;
            top: 0;
            -webkit-transition: 0.3s all;
            -moz-transition: 0.3s all;
            -ms-transition: 0.3s all;
            -o-transition: 0.3s all;
            outline: none;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;

            -moz-background-clip: padding;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;

            &:hover {
                width: 80px;
                height: 80px;
                left: -5px;
                top: -5px
            }

            &:active {
                width: 60px;
                height: 60px;
                left: 5px;
                top: 5px
            }
        }

        p {
            margin: 0;
            font-size: 12px;
            line-height: 12px;
            color: $old-font-blue;
            font-weight: 700;
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;

            @include media-breakpoint-down(xs) {
                display: none;
            }
        }
    }

    .stop-button {
        display: block;
        position: absolute;
        width: 70px;
        height: 70px;
        left: 50%;
        top: -50%;
        margin-left: -35px;
        margin-top: -35px;
        text-align: center;
        z-index: 5;
        -webkit-transition: 0.5s ease-out top;
        -moz-transition: 0.5s ease-out top;
        -ms-transition: 0.5s ease-out top;
        -o-transition: 0.5s ease-out top;

        button {
            left: 0;
            width: 70px;
            height: 70px;
            background: url('../img/video/stop.png') no-repeat center center;
            background-size: cover;
            border: none;
            position: absolute;
            top: 0;
            -webkit-transition: 0.3s all;
            -moz-transition: 0.3s all;
            -ms-transition: 0.3s all;
            -o-transition: 0.3s all;
            outline: none;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            -moz-background-clip: padding;
            -webkit-background-clip: padding-box;
            background-clip: padding-box;

            &:hover {
                width: 80px;
                height: 80px;
                left: -5px;
                top: -5px
            }

            &:active {
                width: 60px;
                height: 60px;
                left: 5px;
                top: 5px
            }
        }
    }

    iframe {
        background-color: #e2f4fe;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}
