/// Style dla "Produktów". Jest to przeniesienie starego ostylowania LESS na Gulp'a.
/// @name Products
/// @group products

.products_heading {
    background-image: url('../img/products/emolient_bg.png');
    background-repeat: no-repeat;
    background-position: right 10% center;
    margin-bottom: 60px; //100px

    @include media-breakpoint-down (xs) {
        margin-bottom: 40px;
    }

    h1 {
        font-family: 'Raleway', sans-serif;
        font-size: 70px;
        font-weight: 200;
        color: $old-accent-pink;
        text-align: center;
        margin-right: 186px;
        line-height: 114px;
        padding-bottom: 35px;
        padding-left: 47px;
        padding-right: 47px;
        padding-top: 10px;
        position: relative;
        margin-bottom: 33px;

        @include media-breakpoint-down (xs) {
            padding: 20px;
            width: 100%;
            font-size: 50px;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 25px;
            height: 25px;
            background-image: url('../img/products/emolient_arrow_left.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center center;
        }

        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            width: 25px;
            height: 25px;
            background-image: url('../img/products/emolient_arrow_right.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: center center;
        }

        span {
            display: block;
            font-family: 'Raleway', sans-serif;
            font-size: 37px;
            font-weight: 600;
            color: #4374a6;
            line-height: 24px;

            @include media-breakpoint-down (xs) {
                font-size: 20px;
            }
        }
    }

    h2 {
        font-family: 'Ubuntu', sans-serif;
        font-size: 16px;
        font-weight: 500;
        color: $old-accent-pink;
    }

    p {
        font-family: 'Ubuntu', sans-serif;
        font-size: 13px;
        font-weight: 300;
        color: $old-font-gray;
        line-height: 20px;
    }
}

.product_info {
    margin-bottom: 80px; //116px

    @include media-breakpoint-down (xs) {
        margin-bottom: 40px;
    }

    &.smaller-container {
        .container {
            /* @include media-breakpoint-down (md) -up {
                width: 1015px;
            } */
        }
    }

    .product_image {
        img {
            @include media-breakpoint-down (xs) {
                width: 100%;
            }
        }
    }

    .info {
        padding-top: 74px;

        @include media-breakpoint-down (xs) {
            padding-top: 0;
        }

        h3 {
            font-family: 'Raleway', sans-serif;
            font-size: 40px;
            font-weight: 700;
            color: #3f3e42;
            line-height: 33px;
            text-transform: initial;

            span {
                display: block;
                font-family: 'Raleway', sans-serif;
                font-size: 30px;
                font-weight: 700;
                color: $old-accent-blue;
                line-height: 33px;
            }
        }

        &.no-padding {
            p {
                padding-right: 20px;
            }
        }

        p {
            color: #2e3033;
            font-family: 'Ubuntu', sans-serif;
            font-size: 13px;
            font-weight: 300;
            line-height: 18px;
            padding-right: 101px;

            @include media-breakpoint-down (xs) {
                padding-right: 0px;
            }

            span {
                display: inline-block;
                font-family: 'Ubuntu', sans-serif;
                font-size: 13px;
                font-weight: 600;


                color: $old-accent-pink;
            }
        }

        span {
            font-family: 'Ubuntu', sans-serif;
            font-size: 13px;
            font-weight: 300;
            line-height: 18px;
            display: block;
            color: $old-accent-pink;
        }

        a.more {
            display: block;
            width: 170px;
            height: 35px;
            background-color: #4374a6;
            color: white;
            font-family: 'Ubuntu', sans-serif;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
            padding-top: 8px;
            margin-top: 26px;
            border: 1px solid #4374a6;

            @include media-breakpoint-down (xs) {
                width: 100%;
                margin: auto;
                margin-top: 26px;
            }

            -webkit-transition: all 0.5s ease;
            -moz-transition: all 0.5s ease;
            -ms-transition: all 0.5s ease;
            -o-transition: all 0.5s ease;

            &:hover {
                text-decoration: none;
                background-color: white;
                color: #4374a6;
            }

            &:focus {
                text-decoration: none;
                background-color: white;
                color: #4374a6;
            }
        }
    }
}

.products {
    margin-bottom: 35px;

    @include media-breakpoint-down (xs) {
        margin-bottom: 20px;
    }

    .container {
        >.row {
            display: flex;

            @include media-breakpoint-down (xs) {
                display: block
            }
        }

    }

    a.more {
        display: block;
        width: 170px;
        height: 35px;
        background-color: #4374a6;
        color: white;
        font-family: 'Ubuntu', sans-serif;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        padding-top: 8px;
        margin-top: 26px;
        border: 1px solid #4374a6;

        @include media-breakpoint-down (xs) {
            width: 100%;
            margin: auto;
            margin-top: 26px;
        }

        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;

        &:hover {
            text-decoration: none;
            background-color: white;
            color: #4374a6;
        }

        &:focus {
            text-decoration: none;
            background-color: white;
            color: #4374a6;
        }
    }



    h3 {
        font-family: 'Raleway', sans-serif;
        font-size: 24px;
        font-weight: 700;
        color: #3f3e42;
        line-height: 23px;
        margin-top: 37px;

        span {
            display: block;
            font-family: 'Raleway', sans-serif;
            font-size: 19px;
            font-weight: 700;
            color: $old-accent-blue;
            line-height: 23px;
            height: auto;
        }

        margin-bottom:24px;
    }

    p {
        color: #2e3033;
        font-family: 'Ubuntu', sans-serif;
        font-size: 14px;
        font-weight: 300;
        margin-bottom: 10px;

        @include media-breakpoint-down (xs) {
            padding-right: 0px;
        }

        span {
            display: inline-block;
            font-family: 'Ubuntu', sans-serif;
            font-size: 14px;
            font-weight: 600;
            color: $old-accent-pink;
        }
    }

    span {
        font-family: 'Ubuntu', sans-serif;
        font-size: 14px;
        font-weight: 300;
        display: block;
        color: $old-accent-pink;
    }

    .left-prod {
        display: flex;
        margin-bottom: 40px;
        float: left;

        @include media-breakpoint-down (xs) {
            display: block;
            width: 100%;
            margin-bottom: 40px;
        }

        .cont {
            float: left;
            padding-left: 50px;
            padding-right: 50px;
            padding-top: 22px;
            padding-bottom: 22px;
            background-color: #f1f9fe;
            background-image: url('../img/products/olejek.png');
            background-repeat: no-repeat;
            background-position: left bottom;

            @include media-breakpoint-down (xs) {
                padding: 20px;
                text-align: center;
                width: 100%;
            }

            .image {
                img {
                    @include media-breakpoint-down (xs) {
                        width: 100%;
                    }
                }
            }
        }
    }

    .right-prod {
        display: flex;
        margin-bottom: 40px;
        float: left;

        @include media-breakpoint-down (xs) {
            display: block;
            width: 100%;
            margin-bottom: 20px;
        }

        .cont {
            float: left;
            padding-left: 50px;
            padding-right: 50px;
            padding-top: 22px;
            padding-bottom: 22px;
            background-color: #f1f9fe;
            background-image: url('../img/products/woda.png');
            background-repeat: no-repeat;
            background-position: left bottom;

            @include media-breakpoint-down (xs) {
                padding: 20px;
                text-align: center;
                width: 100%;
            }

            .image {
                img {
                    @include media-breakpoint-down (xs) {
                        width: 100%;
                    }
                }
            }
        }
    }
}

.why_atoderm_table {
    &.smaller-container {
        .container {
            /* @include media-breakpoint-down (md) -up {
                width: 1015px;
            } */
        }
    }

    background-image: url('../img/products/table_bg.png');
    background-repeat:no-repeat;
    background-position: right bottom;
    padding-bottom: 30px;

    .table-responsive {
        border: none !important;

        @include media-breakpoint-down (xs) {
            box-shadow: inset -15px 0 43px -26px #000;
        }
    }

    p {
        font-family: 'Ubuntu', sans-serif;
        font-size: 10px;
        font-weight: 300;
        color: #2e3033;
        margin: 0;
        line-height: 18px;
    }

    table {
        margin-bottom: 56px !important;
        z-index: -1;
        position: relative;

        thead {
            tr {
                th {
                    border-bottom: 2px solid $old-accent-pink;

                    h3 {
                        margin: 0;
                        padding: 0;
                        text-align: left;
                        font-family: 'Ubuntu', sans-serif;
                        font-size: 25px;
                        font-weight: 400;
                        color: $old-accent-pink;

                        span {
                            font-family: 'Ubuntu', sans-serif;
                            font-size: 25px;
                            font-weight: 700;
                            color: #4374a6;
                        }
                    }

                    font-family: "Raleway", sans-serif;
                    font-size: 16px;
                    font-weight: 600;
                    color: $old-accent-pink;
                    text-align:center;
                }
            }
        }

        tbody {
            margin-top: 11px;

            tr {

                &:nth-of-type(odd) {
                    background-color: white;

                    td {
                        border: none;
                        border-right: 30px solid white;
                        background-color: white;

                        @include media-breakpoint-down (xs) {
                            border: none;
                        }

                        &:last-of-type {
                            border-right: 0px;
                        }
                    }
                }

                td {
                    border: none;
                    background-color: #eff5f9;
                    padding-top: 11px;
                    padding-bottom: 11px;
                    padding-left: 17px;
                    padding-right: 17px;
                    font-family: 'Ubuntu', sans-serif;
                    font-size: 14px;
                    font-weight: 300;
                    color: #2e3033;
                    position: relative;
                    border-right: 30px solid white;
                    min-width: 160px;

                    @include media-breakpoint-down (xs) {
                        border: none;
                    }

                    &:last-of-type {
                        border-right: 0px;
                    }

                    .okay {
                        width: 20px;
                        height: 16px;
                        position: absolute;
                        background-image: url('../img/products/okay.png');
                        margin: auto;
                        left: 0;
                        right: 0;
                    }
                }
            }
        }
    }
}
