div.rect {
    overflow: hidden;
    background: #f1f9fe;
    position: relative;
    padding-bottom: 85%;

    @include media-breakpoint-down (xs) {
        padding-bottom: 0;
        height: auto
    }

    &:before {
        @include corner(#fff, 30px);
        content: '';
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
    }

    &:after {
        @include corner($old-font-blue);
        content: '';
        position: absolute;
        z-index: 2;
        left: 0;
        top: 0
    }

    // Zdjęcie wewnątrz kafelka
    .embed {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        min-width: 100%;
        min-height: 100%;
        z-index: 0;

        &--mobile {
            display: none !important;

            @include media-breakpoint-down (md) {
                display: block !important;
            }
        }
    }

    .inner {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        padding: 50px 50px 100px 50px;
        width: 100%;
        height: 100%;
        z-index: 3;

        @include media-breakpoint-down (xs) {
            position: relative;
            padding: 40px 40px 100px 40px;
        }

        h2 {
            font-size: 28px;
            line-height: 30px;
            color: $old-font-blue;
            width: 50%;

            @include media-breakpoint-down (sm) {
                width: 100%;
                margin-bottom: 15px
            }
        }

        p {
            @include media-breakpoint-down (md) {
                height: auto;
            }

            @include media-breakpoint-down (sm) {
                height: auto;
                margin-bottom: 15px
            }

            @include media-breakpoint-down (xs) {
                height: auto
            }
        }

        a {
            position: absolute;
            bottom: 30px;
        }
    }

    .button {
        border: 1px solid #f1f9fe;

        &:hover {
            background: #f1f9fe;
        }
    }

    &.blue {
        background: #f1f9fe;

        &:after {
            @include corner($old-accent-blue);
            content: '';
            position: absolute;
            left: 0;
            top: 0
        }

        .inner {
            h2 {
                color: $old-accent-blue
            }
        }

        .button {
            background: $old-accent-blue;
            border: 1px solid #f1f9fe;

            &:hover {
                background: #f1f9fe;
            }
        }
    }

    &.pink {
        background: #f8f8f8;

        &:after {
            @include corner($old-accent-pink);
            content: '';
            position: absolute;
            left: 0;
            top: 0
        }

        .inner {
            h2 {
                color: $old-accent-pink
            }
        }

        .button {
            background: $old-accent-pink;
            border: 1px solid #f8f8f8;

            &:hover {
                background: #f8f8f8;
            }
        }
    }

    @include media-breakpoint-down (sm) {
        margin-bottom: 30px
    }

    @include media-breakpoint-down (md) {
        margin-bottom: 30px
    }

    @include media-breakpoint-down (xs) {
        margin-bottom: 30px
    }
}