.slick-slide .banner-mobile-img {
    display: none;

    @include media-breakpoint-down (sm) {
        display: block;
        position: absolute;
        bottom: 0;
        max-width: 100%;
        height: auto;
        z-index: 1;
    }
}

header {
    .banner {

        @include media-breakpoint-down (sm) {
            background-image: none !important;
        }

        .container {
            position: relative;
            z-index: 5;
        }

        p {
            font-size: 16px;
            line-height: 24px;
        }
    }
}
