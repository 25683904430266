
/// Style dla sekcji Footer. Jest to przeniesienie starego ostylowania LESS na Gulp'a.
/// @name Footer
/// @group footer
footer {
    background: #fff;
    padding: 50px 0;

    @include media-breakpoint-down (xs) {
        padding: 40px 0;
    }

    .bioderma {
        margin-bottom: 20px
    }

    ul.icon-list {
        padding: 0 0 10px 0;
        list-style: none;
        display: inline-block;

        >li {
            display: inline-block;
            margin-left: 15px;
            margin-right: 15px
        }

        border-bottom: 1px solid #c2c2c2;
    }

    h5 {
        margin-top: 20px;
        font-size: 10px;
        color: #626262
    }

    &.sticky {
        position: relative;
    }
}

.page-template-atodermolandia {
    footer {
        padding: 0 0 50px 0;
    }
}
