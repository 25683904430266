.button {
    color: #fff;
    border-radius: 0;
    background: $old-font-blue;
    border: 1px solid $old-font-blue;
    min-height: 37px;
    font-size: 12px;
    font-weight: 400;
    display: inline-block;
    margin: 5px 10px 5px 0;
    padding: 10px 25px;
    max-width: 100%;
    -webkit-transition: 0.3s all;
    -moz-transition: 0.3s all;
    -ms-transition: 0.3s all;
    -o-transition: 0.3s all;

    &:hover {
        background: transparent;
        color: $old-font-blue
    }

    &-blue {
        background: $old-accent-blue;
        border: 1px solid $old-accent-blue;

        &:hover {
            background: transparent;
            color: $old-accent-blue;
        }
    }

    &-grey {
        background: $old-accent-grey;
        border: 1px solid $old-accent-grey;

        &:hover {
            background: transparent;
            color: $old-accent-grey;
        }
    }
}

a.button {
    text-decoration: none;

    &:hover,
    &:active,
    &:visited,
    &:focus {
        text-decoration: none
    }
}
