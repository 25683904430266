.e404 {
    padding: 200px 0 150px 0;

    @include media-breakpoint-down (md) {
        padding: 50px 0;
    }

    &__row {
        text-align: center;
    }

    &__title {
        color: $color-second;
        font-family: $default-font;
        font-weight: 700;
    }

    &__button {
        display: inline-block;
        margin-top: 50px;
        padding: 16px;
        border: 1px solid transparent;
        color: #fff;
        background-color: $color-second;
        font-family: $default-font;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        transition: all 0.5s;

        &:hover,
        &:focus,
        &:active {
            color: #fff;
            background-color: $color-first;
            border-color: $color-first-dark;
            text-decoration: none;
        }

        @include media-breakpoint-down (md) {
            padding: 10px;
            font-size: 18px;
        }
    }
}
