
/// Style dla belki z informacją o Cookies.
/// @name Cookie-Info
/// @group other
.cookie-info {
    display: none;
    position: fixed;
    bottom: 0;
    width: 100vw;
    padding: 20px 0 10px 0;
    background-color: $old-accent-grey;
    z-index: 1000;

    &__text {
        margin: 0;
        color: #fff;
        font-family: $default-font;
        font-size: 12px;
        font-weight: 500;
        text-align: center;

        @include media-breakpoint-down (md) {
            margin-bottom: 10px;
        }
    }

    &__buttons {
        margin-top: 8px;
        text-align: center;
    }

    &__button {
        margin: 0 13px;
        padding: 6px 16px;
        border: 1px solid transparent;
        color: #fff;
        background-color: $color-second;
        font-family: $default-font;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        transition: all 0.25s;

        &:hover,
        &:focus,
        &:active {
            color: #fff;
            background-color: $color-first;
            border-color: $color-first-dark;
        }

        @include media-breakpoint-down (md) {
            margin: 0 3px;
            padding: 6px 10px;
        }
    }

    &__icon-check-mark {
        margin-right: 2px;
        vertical-align: middle;
    }
}
