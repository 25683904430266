// Usage:   @include transition(width, height 0.3s ease-in-out);
// Output:  transition(width 0.2s, height 0.3s ease-in-out);
//
// Pass in any number of transitions
@mixin transition($transitions...) {
    $unfoldedTransitions: ();
 
    @each $transition in $transitions {
       $unfoldedTransitions: append($unfoldedTransitions, unfoldTransition($transition), comma);
    }
    
    transition: $unfoldedTransitions;
 }

 @function unfoldTransition ($transition) {
    // Default values
    $property: all;
    $duration: .2s;
    $easing: ease-out; // Browser default is ease, which is what we want
    $delay: null; // Browser default is 0, which is what we want
    $defaultProperties: ($property, $duration, $easing, $delay);
  
    // Grab transition properties if they exist
    $unfoldedTransition: ();
    @for $i from 1 through length($defaultProperties) {
        $p: null;
        @if $i <= length($transition) {
            $p: nth($transition, $i)
        }
        @else {
            $p: nth($defaultProperties, $i)
        }
        $unfoldedTransition: append($unfoldedTransition, $p);
    }
    @return $unfoldedTransition;
}

@mixin corner( $color, $size: 20px )
{
    border-left: $size solid $color;
    border-top: $size solid $color;
    border-right: $size solid transparent;
    border-bottom: $size solid transparent;
}

//
//  MEDIA QUERIES
//––––––––––––––––––––––––––––––––––––––––––––––––––

$breakpoints: (
    xxs: 480px,
    xs: 576px,
    sm: 768px,
    md: 992px,
    lg: 1200px,
    xl: 1500px,
);

//
//  RESPOND BELOW
//––––––––––––––––––––––––––––––––––––––––––––––––––

// @include media-breakpoint-down(sm) {}
@mixin media-breakpoint-down($breakpoint) {

    // If the breakpoint exists in the map.
    @if map-has-key($breakpoints, $breakpoint) {

        // Get the breakpoint value.
        $breakpoint-value: map-get($breakpoints, $breakpoint);

        // Write the media query.
        @media (max-width: ($breakpoint-value - 1)) {
            @content;
        }
        // If the breakpoint doesn't exist in the map.
    }
    @else {
        // Log a warning.
        @warn 'Invalid breakpoint: #{$breakpoint}.';
    }
}
